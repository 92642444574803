/*eslint-disable*/
import React from "react";
import PropTypes from "prop-types";
// javascript plugin used to create scrollbars on windows
import PerfectScrollbar from "perfect-scrollbar";
import { NavLink } from "react-router-dom";
import cx from "classnames";

// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Hidden from "@material-ui/core/Hidden";
import Collapse from "@material-ui/core/Collapse";
import Icon from "@material-ui/core/Icon";

// core components
import AdminNavbarLinks from "../Navbars/AdminNavbarLinks.js";
//
import sidebarStyle from "../../assets/jss/material-dashboard-pro-react/components/sidebarStyle.js";

import defaultAvatar from "../../assets/img/icon-avatar-default.png";

import {SidebarWrapper} from "./SidebarWrapper";
import {NavigationUtils} from "../../utils/NavigationUtils";
import {Networking} from "../../services/Networking";

class Sidebar extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            openAvatar: false,
            miniActive: true,
            ...this.getCollapseStates(props.routes)
        };
    }

    mainPanel;

    // this creates the intial state of this component based on the collapse routes
    // that it gets through this.props.routes
    getCollapseStates = routes => {
        let initialState = {};
        routes?.map(prop => {
            if (prop.collapse) {
                initialState = {
                    [prop.state]: this.getCollapseInitialState(prop.views),
                    ...this.getCollapseStates(prop.views),
                    ...initialState
                };
            }
            return null;
        });
        return initialState;
    };
    // this verifies if any of the collapses should be default opened on a rerender of this component
    // for example, on the refresh of the page,
    // while on the src/views/forms/RegularForms.jsx - route /admin/regular-forms
    getCollapseInitialState(routes) {
        for (let i = 0; i < routes.length; i++) {
            if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
                return true;
            } else if (window.location.href.indexOf(routes[i].path) !== -1) {
                return true;
            }
        }
        return false;
    }

    // verifies if routeName is the one active (in browser input)
    activeRoute = routeName => {
        return window.location.href.indexOf(routeName) > -1 ? "active" : "";
    };

    openCollapse(collapse) {
        var st = {};
        st[collapse] = !this.state[collapse];
        this.setState(st);
    }

    // this function creates the links and collapses that appear in the sidebar (left menu)
    createLinks = routes => {
        const {classes, color, rtlActive} = this.props;
        return routes?.map((prop, key) => {
            if (prop.redirect) {
                return null;
            }
            if (prop.collapse) {
                var st = {};
                st[prop["state"]] = !this.state[prop.state];
                const navLinkClasses = classes.itemLink + " " + cx({
                    [" " + classes.collapseActive]: this.getCollapseInitialState(prop.views)
                });
                const itemText = classes.itemText + " " + cx({
                    [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
                });
                const collapseItemText = classes.collapseItemText + " " + cx({
                    [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive
                });
                const itemIcon = classes.itemIcon;
                const caret = classes.caret;
                const collapseItemMini = classes.collapseItemMini;
                return (
                    <ListItem
                        key={key}
                        className={cx(
                            {[classes.item]: prop.icon !== undefined},
                            {[classes.collapseItem]: prop.icon === undefined}
                        )}
                    >
                        <NavLink
                            to={"#"}
                            className={navLinkClasses}
                            onClick={e => {
                                e.preventDefault();
                                this.setState(st);
                            }}
                        >
                            {prop.icon !== undefined ? (
                                typeof prop.icon === "string" ? (
                                    <Icon className={itemIcon}>{prop.icon}</Icon>
                                ) : (
                                    <prop.icon className={itemIcon}/>
                                )
                            ) : (
                                <span className={collapseItemMini}>{prop.mini}</span>
                            )}
                            <ListItemText
                                primary={prop.name}
                                secondary={
                                    <b className={caret + " " + (this.state[prop.state] ? classes.caretActive : "")}/>
                                }
                                disableTypography={true}
                                className={cx(
                                    {[itemText]: prop.icon !== undefined},
                                    {[collapseItemText]: prop.icon === undefined}
                                )}
                            />
                        </NavLink>
                        <Collapse in={this.state[prop.state]} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                {this.createLinks(prop.views)}
                            </List>
                        </Collapse>
                    </ListItem>
                );
            }
            const innerNavLinkClasses = classes.collapseItemLink + " " + cx({
                [" " + classes[color]]: this.activeRoute(prop.path)
            });
            const collapseItemMini = classes.collapseItemMini;
            const navLinkClasses = classes.itemLink + " " + cx({[" " + classes[color]]: this.activeRoute(prop.path)});
            const itemText = classes.itemText + " " + cx({
                [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
            });
            const collapseItemText = classes.collapseItemText + " " + cx({
                [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive
            });
            const itemIcon = classes.itemIcon;

            return (
                <ListItem
                    key={key}
                    className={cx(
                        {[classes.item]: prop.icon !== undefined},
                        {[classes.collapseItem]: prop.icon === undefined}
                    )}
                >
                    <NavLink
                        to={prop.layout + prop.path}
                        className={cx(
                            {[navLinkClasses]: prop.icon !== undefined},
                            {[innerNavLinkClasses]: prop.icon === undefined}
                        )}
                        onClick={e => {
                            // e.preventDefault();
                            this.props.onClick(prop.path);
                            // this.setState(st);
                        }}
                    >
                        {prop.icon !== undefined ? (
                                typeof prop.icon === "string" ? (<Icon className={itemIcon}>{prop.icon}</Icon>) :
                                    (<prop.icon className={itemIcon}/>)) :
                            (<span className={collapseItemMini}>{prop.mini}</span>)
                        }
                        <ListItemText
                            primary={rtlActive ? prop.rtlName : prop.name}
                            disableTypography={true}
                            className={cx(
                                {[itemText]: prop.icon !== undefined},
                                {[collapseItemText]: prop.icon === undefined}
                            )}
                        />
                    </NavLink>
                </ListItem>
            );
        });
    };

    createProfileCollapseTab(text) {
        const classes = this.props.classes;
        const collapseItemText = classes.collapseItemText + " " + cx({
            [classes.collapseItemTextMini]: this.props.miniActive && this.state.miniActive
        });
        return (
            <ListItem className={classes.collapseItem}>
                <NavLink to={text.toLowerCase()} className={classes.itemLink + " " + classes.userCollapseLinks} onClick={e => {
                    // e.preventDefault();
                    this.props.onClick(NavigationUtils.getPath());
                }}>
                    <span className={classes.collapseItemMini}>{text[0].toUpperCase()}</span>
                    <ListItemText primary={text} disableTypography={true} className={collapseItemText}/>
                </NavLink>
            </ListItem>
        );
    }

    getAvatarPath() {
        if (!this.props.user?.avatarUrl) {
            return defaultAvatar;
        }
        return Networking.instance.BASE_PATH + this.props.user.avatarUrl;
    }
    render() {
        const { classes, logo, image, logoText, routes, bgColor, rtlActive} = this.props;
        const itemText = classes.itemText + " " + cx({
            [classes.itemTextMini]: this.props.miniActive && this.state.miniActive
        });
        const userWrapperClass = classes.user + " " + cx({ [classes.whiteAfter]: bgColor === "white" });

        const user = (
            <div className={userWrapperClass}>
                <div className={classes.photo}>
                    <img src={this.getAvatarPath()} className={classes.avatarImg} alt="..."/>
                </div>
                <List className={classes.list}>
                    <ListItem className={classes.item + " " + classes.userItem}>
                        <NavLink
                            to={"#"}
                            className={classes.itemLink + " " + classes.userCollapseButton}
                            onClick={() => this.openCollapse("openAvatar")}
                        >
                            <ListItemText
                                primary={this.props.user?.name}
                                secondary={
                                    <b
                                        className={classes.caret + " " + classes.userCaret + " " +
                                        (this.state.openAvatar ? classes.caretActive : "")
                                        }
                                    />
                                }
                                disableTypography={true}
                                className={itemText + " " + classes.userItemText}
                            />
                        </NavLink>
                        <Collapse in={this.state.openAvatar} unmountOnExit>
                            <List className={classes.list + " " + classes.collapseList}>
                                {this.createProfileCollapseTab("Profile")}
                                {this.createProfileCollapseTab("Settings")}
                            </List>
                        </Collapse>
                    </ListItem>
                </List>
            </div>
        );
        const links = (<List className={classes.list}>{this.createLinks(routes)}</List>);

        const logoNormal = classes.logoNormal + " " + cx({
            [classes.logoNormalSidebarMini]: this.props.miniActive && this.state.miniActive
        });
        const logoMini = classes.logoMini;
        const logoClasses = classes.logo + " " + cx({[classes.whiteAfter]: bgColor === "white"});
        const brand = (
            <div className={logoClasses}>
                <a href="/" className={logoMini}><img src={logo} alt="logo" className={classes.img}/></a>
                <a href="/" className={logoNormal}>{logoText}</a>
            </div>
        );
        const drawerPaper = classes.drawerPaper + " " + cx({
            [classes.drawerPaperMini]: this.props.miniActive && this.state.miniActive
        });
        const sidebarWrapper = classes.sidebarWrapper + " " + cx({
            [classes.drawerPaperMini]:
            this.props.miniActive && this.state.miniActive,
            [classes.sidebarWrapperWithPerfectScrollbar]:
            navigator.platform.indexOf("Win") > -1
        });

        return (
            <div ref={ref => this.mainPanel = ref}>
                <Hidden mdUp implementation="css">
                    <Drawer
                        variant="temporary"
                        anchor={"right"}
                        open={this.props.open}
                        classes={{paper: drawerPaper + " " + classes[bgColor + "Background"]}}
                        onClose={this.props.handleDrawerToggle}
                        // Better open performance on mobile.
                        ModalProps={{keepMounted: true}}
                    >
                        {brand}
                        <SidebarWrapper
                            className={sidebarWrapper}
                            user={user}
                            headerLinks={<AdminNavbarLinks/>}
                            links={links}
                        />
                        {this.mapBackgroundImage(classes, image)}
                    </Drawer>
                </Hidden>
                <Hidden smDown implementation="css">
                    <Drawer
                        onMouseOver={() => this.setState({miniActive: false})}
                        onMouseOut={() => this.setState({miniActive: true})}
                        anchor={"left"}
                        variant="permanent"
                        open
                        classes={{paper: drawerPaper + " " + classes[bgColor + "Background"]}}
                    >
                        {brand}
                        <SidebarWrapper className={sidebarWrapper} user={user} links={links}/>
                        {this.mapBackgroundImage(classes, image)}
                    </Drawer>
                </Hidden>
            </div>
        );
    }

    mapBackgroundImage(classes, image) {
        const result = <div className={classes.background} style={{backgroundImage: "url(" + image + ")"}}/>;
        return (image !== undefined) ? result : null;
    }
}

Sidebar.defaultProps = {
    bgColor: "blue"
};

Sidebar.propTypes = {
    classes: PropTypes.object.isRequired,
    bgColor: PropTypes.oneOf(["white", "black", "blue"]),
    rtlActive: PropTypes.bool,
    color: PropTypes.oneOf([
        "white",
        "red",
        "orange",
        "green",
        "blue",
        "purple",
        "rose"
    ]),
    logo: PropTypes.string,
    logoText: PropTypes.string,
    image: PropTypes.string,
    routes: PropTypes.arrayOf(PropTypes.object),
    miniActive: PropTypes.bool,
    open: PropTypes.bool,
    handleDrawerToggle: PropTypes.func
};

SidebarWrapper.propTypes = {
    className: PropTypes.string,
    user: PropTypes.object,
    headerLinks: PropTypes.object,
    links: PropTypes.object
};

export default withStyles(sidebarStyle)(Sidebar);
