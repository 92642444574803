import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

import en from "./assets/translations/en.json";
import et from "./assets/translations/et.json";
import {LocalStorage} from "./services/LocalStorage";

// https://react.i18next.com/guides/quick-start
i18n.use(Backend).use(initReactI18next).init({
    // resources,
    lng: LocalStorage.instance.settings.language,
    keySeparator: false,
    debug: true,
    interpolation: { escapeValue: false },
    fallbackLng: 'en',
    preload: ['en', 'et'],
    ns: ['translation'],
    defaultNS: 'translation',
    backend: {
        loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    react: {
        useSuspense: false
    }
}, (err, t) => {
    if (err) return console.error(err)
    // console.log(t('welcome'))
    // console.log(t('welcome', { lng: 'et' }))
});

export default i18n;
