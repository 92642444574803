import {Settings} from "./Settings";

export class LocalStorage {

    public static instance = new LocalStorage();

    constructor() {
        this.settings = new Settings(this.get(this.SETTINGS_KEY));
    }
    public isLoggedIn() {
        return !!this.getSessionToken();
    }

    TOKEN_KEY = "allicater-global-session-id";
    getSessionToken() {
        return this.get(this.TOKEN_KEY);
    }
    setSessionToken(value: string) {
        this.set(this.TOKEN_KEY, value);
    }
    deleteSessionToken() {
        this.remove(this.TOKEN_KEY);
    }

    public set(key: string, value: string) {
        localStorage.setItem(key, value);
    }
    public get(key: string): any {
        return localStorage.getItem(key);
    }
    public remove(key: string) {
        localStorage.removeItem(key);
    }

    public clear() {
        localStorage.clear();
    }

    SETTINGS_KEY = "allicater-settings";
    updateLanguage(language: LanguageSettings) {
        this.settings.language = language;
        this.set(this.SETTINGS_KEY, JSON.stringify(this.settings));
    }
    settings: Settings;
}

export type LanguageSettings = "en" | "et";
