import React from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import MenuItem from "@material-ui/core/MenuItem";
import MenuList from "@material-ui/core/MenuList";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import Paper from "@material-ui/core/Paper";
import Grow from "@material-ui/core/Grow";
import Hidden from "@material-ui/core/Hidden";
import Popper from "@material-ui/core/Popper";
import Divider from "@material-ui/core/Divider";

// @material-ui/icons
import Person from "@material-ui/icons/Person";
import Notifications from "@material-ui/icons/Notifications";
import Dashboard from "@material-ui/icons/Dashboard";

// core components
import Button from "../CustomButtons/Button.js";

import { withStyles } from "@material-ui/core/styles";
import styles from "../../assets/jss/material-dashboard-pro-react/components/adminNavbarLinksStyle.js";

class AdminNavbarLinks extends React.Component {

    constructor(props) {
        super(props);
        this.classes = props.classes;

        this.dropdownItem = classNames(this.classes.dropdownItem, this.classes.primaryHover, {[this.classes.dropdownItemRTL]: this.props.rtlActive});
        this.state = {
            openNotification: null,
            openProfile: null
        }

    }

    handleClickNotification = event => {
        if (this.state.openNotification && this.state.openNotification.contains(event.target)) {
            this.setState({openNotification: null});
        } else {
            this.setState({openNotification: event.currentTarget});
        }
    };
    handleCloseNotification = () => {
        this.setState({openNotification: null});
    };

    handleClickProfile = event => {
        if (this.state.openProfile && this.state.openProfile.contains(event.target)) {
            this.setState({openProfile: null});
        } else {
            this.setState({openProfile: event.currentTarget});
        }
    };
    handleCloseProfile = () => {
        this.setState({openProfile: null})
    };

    render() {
        const classes = this.classes;
        const managerClasses = classNames({[classes.managerClasses]: true});

        return (
            <div>
                <Button
                    color="transparent" simple aria-label="Dashboard" justIcon className={classes.buttonLink}
                    muiClasses={{label: ""}} onClick={this.props.dashboardClick}>
                    <Dashboard className={classes.headerLinksSvg + " " + classes.links}/>
                    <Hidden mdUp implementation="css">
                        <span className={classes.linkText}>{"Dashboard"}</span>
                    </Hidden>
                </Button>
                <div className={managerClasses}>
                    <Button
                        color="transparent"
                        justIcon
                        aria-label="Notifications"
                        aria-owns={this.openNotification ? "notification-menu-list" : null}
                        aria-haspopup="true"
                        onClick={this.handleClickNotification}
                        className={classes.buttonLink}
                        muiClasses={{label: ""}}
                    >
                        <Notifications className={classes.headerLinksSvg + " " + classes.links}/>
                        <span className={classes.notifications}>0</span>
                        <Hidden mdUp implementation="css">
                            <span onClick={this.handleClickNotification} className={classes.linkText}>{"Notification"}</span>
                        </Hidden>
                    </Button>
                    {this.createNotificationPopper(classes)}
                </div>

                <div className={managerClasses}>
                    <Button
                        color="transparent"
                        aria-label="Person"
                        justIcon
                        aria-owns={this.state.openProfile ? "profile-menu-list" : null}
                        aria-haspopup="true"
                        onClick={this.handleClickProfile}
                        className={classes.buttonLink}
                        muiClasses={{label: ""}}
                    >
                        <Person className={classes.headerLinksSvg + " " + classes.links}/>
                        <Hidden mdUp implementation="css">
                            <span onClick={this.handleClickProfile} className={classes.linkText}>{"Profile"}</span>
                        </Hidden>
                    </Button>
                    {this.createUserPopper(classes)}
                </div>
            </div>
        );
    }

    createNotificationPopper(classes) {
        return (
            <Popper
                open={Boolean(this.state.openNotification)}
                anchorEl={this.state.openNotification}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                    [classes.popperClose]: !this.state.openNotification,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: true
                })}
            >
                {({TransitionProps}) => (
                    <Grow {...TransitionProps} id="notification-menu-list" style={{transformOrigin: "0 0 0"}}>
                        <Paper className={classes.dropdown}>
                            <ClickAwayListener onClickAway={this.handleCloseNotification}>
                                <MenuList role="menu">
                                    {/*{this.createNotificationContent("Mike John responded to your email")}*/}
                                    {/*{this.createNotificationContent("You have 5 new tasks")}*/}
                                    {/*{this.createNotificationContent("You're now friend with Andrew")}*/}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>);
    }

    createUserPopper(classes) {
        return (
            <Popper
                open={Boolean(this.state.openProfile)}
                anchorEl={this.state.openProfile}
                transition
                disablePortal
                placement="bottom"
                className={classNames({
                    [classes.popperClose]: !this.state.openProfile,
                    [classes.popperResponsive]: true,
                    [classes.popperNav]: true
                })}
            >
                {({TransitionProps}) => (
                    <Grow {...TransitionProps} id="profile-menu-list" style={{transformOrigin: "0 0 0"}}>
                        <Paper className={classes.dropdown}>
                            <ClickAwayListener onClickAway={this.handleCloseProfile}>
                                <MenuList role="menu">
                                    {this.createUserContent("Profile", this.props.profileClick)}
                                    {this.createUserContent("Settings", this.props.settingsClick)}
                                    <Divider/>
                                    {this.createUserContent("Log out", this.props.logoutClick)}
                                </MenuList>
                            </ClickAwayListener>
                        </Paper>
                    </Grow>
                )}
            </Popper>
        );
    }

    createNotificationContent(text) {
        return <MenuItem onClick={this.handleCloseNotification} className={this.dropdownItem}>{text}</MenuItem>
    }

    createUserContent(text, action) {
        return <MenuItem onClick={() => {
            this.setState({openProfile: null});
            action();
        }} className={this.dropdownItem}>{text}</MenuItem>
    }
}

export default withStyles(styles)(AdminNavbarLinks);

AdminNavbarLinks.propTypes = {
    rtlActive: PropTypes.bool
};
