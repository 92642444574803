import {grayColor} from "../assets/jss/material-dashboard-pro-react";

export class Styles {
    static title = {
        color: grayColor[2],
        textDecoration: "none",
        fontWeight: "300",
        marginTop: "30px",
        marginBottom: "25px",
        minHeight: "32px",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        "& small": {
            color: grayColor[1],
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    };

    static cardTitle = {
        ...Styles.title,
        marginTop: "0",
        marginBottom: "3px",
        minHeight: "auto",
        "& a": {
            ...Styles.title,
            marginTop: ".625rem",
            marginBottom: "0.75rem",
            minHeight: "auto"
        }
    };

    static cardIconTitle = {
        ...Styles.cardTitle,
        marginTop: "15px",
        marginBottom: "0px"
    };

    static checkboxAndRadioHorizontal: {
        position: "relative",
        display: "block",
        "&:first-child": {
            marginTop: "10px"
        },
        "&:not(:first-child)": {
            marginTop: "-14px"
        },
        marginTop: "0",
        marginBottom: "0"
    };
}
