import React from "react";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";

// @material-ui/icons
import PermIdentity from "@material-ui/icons/PermIdentity";

// core components
import GridContainer from "../components/Grid/GridContainer.js";
import GridItem from "../components/Grid/GridItem.js";
import Button from "../components/CustomButtons/Button.js";
import CustomInput from "../components/CustomInput/CustomInput.js";
import Clearfix from "../components/Clearfix/Clearfix.js";
import Card from "../components/Card/Card.js";
import CardBody from "../components/Card/CardBody.js";
import CardHeader from "../components/Card/CardHeader.js";
import CardIcon from "../components/Card/CardIcon.js";
import CardAvatar from "../components/Card/CardAvatar.js";

import styles from "../assets/jss/material-dashboard-pro-react/views/userProfileStyles.js";
import { withStyles } from "@material-ui/core/styles";

import avatar from "../assets/img/faces/marc.jpg";
import {Styles} from "../services/Styles";

class ProfilePage extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {
        return (
            <div>
                <GridContainer>
                    <GridItem xs={12} sm={12} md={8}>
                        <Card>
                            <CardHeader color="rose" icon>
                                <CardIcon color="rose">
                                    <PermIdentity/>
                                </CardIcon>
                                <h4 style={Styles.cardIconTitle}>
                                    Edit Profile - <small>Complete your profile</small>
                                </h4>
                            </CardHeader>
                            <CardBody>
                                <GridContainer>
                                    {this.createGridItem("company-disabled", "Company (disabled)", 5,
                                        {disabled: true})}
                                    {this.createGridItem("username", "Username", 3)}
                                    {this.createGridItem("email-address", "Email address", 4)}
                                </GridContainer>
                                <GridContainer>
                                    {this.createGridItem("first-name", "First Name", 6)}
                                    {this.createGridItem("last-name", "Last Name", 6)}
                                </GridContainer>
                                <GridContainer>
                                    {this.createGridItem("city", "City", 4)}
                                    {this.createGridItem("country", "Country", 4)}
                                    {this.createGridItem("postal-code", "Postal Code", 4)}
                                </GridContainer>
                                <GridContainer>
                                    {this.createGridItem("about-me", "About...", 12, {
                                        multiline: true, rows: 5
                                    })}
                                </GridContainer>
                                <Button color="rose" className={this.props.classes.updateProfileButton}>
                                    Update Profile
                                </Button>
                                <Clearfix/>
                            </CardBody>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={12} md={4}>
                        <Card profile>
                            <CardAvatar profile>
                                <a href="#pablo" onClick={e => e.preventDefault()}>
                                    <img src={avatar} alt="..."/>
                                </a>
                            </CardAvatar>
                            <CardBody profile>
                                <h6 className={this.props.classes.cardCategory}>CEO / CO-FOUNDER</h6>
                                <h4 className={this.props.classes.cardTitle}>Alec Thompson</h4>
                                <p className={this.props.classes.description}>
                                    Don{"'"}t be scared of the truth because we need to restart the
                                    human foundation in truth And I love you like Kanye loves Kanye
                                    I love Rick Owens’ bed design but the back is...
                                </p>
                                <Button color="rose" round>
                                    Follow
                                </Button>
                            </CardBody>
                        </Card>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    createGridItem(id, title, width, inputProps) {
        return (
            <GridItem xs={12} sm={12} md={width}>
                <CustomInput
                    id={id}
                    labelText={title}
                    formControlProps={{fullWidth: true}}
                    inputProps={inputProps}
                />
            </GridItem>
        );
    }
}

export default withStyles(styles)(ProfilePage)
