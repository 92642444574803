import {Settings, SvgIconComponent} from "@material-ui/icons";
import React from "react";
import GridItem from "../components/Grid/GridItem";
import Card from "../components/Card/Card";
import CardHeader from "../components/Card/CardHeader";
import CardIcon from "../components/Card/CardIcon";
import {Styles} from "../services/Styles";
import CardBody from "../components/Card/CardBody";
import GridContainer from "../components/Grid/GridContainer";

export class ViewUtils {

    static createCardTemplate(icon: JSX.Element, title: string, content: React.Component | null) {
        return (
            <GridContainer>
                {/*@ts-ignore*/}
                <GridItem xs={12}>
                    <Card>
                        <CardHeader color="primary" icon>
                            <CardIcon color="primary">
                                {icon}
                            </CardIcon>
                            {/*@ts-ignore*/}
                            <h4 style={Styles.cardIconTitle}>{title}</h4>
                        </CardHeader>
                        <CardBody>
                            {content}
                        </CardBody>
                    </Card>
                </GridItem>
            </GridContainer>
        );
    }
}
