import React from "react";
import PerfectScrollbar from "perfect-scrollbar";

var ps;

// We've created this component so we can have a ref to the wrapper of the links that appears in our sidebar.
// This was necessary so that we could initialize PerfectScrollbar on the links.
// There might be something with the Hidden component from material-ui, and we didn't have access to
// the links, and couldn't initialize the plugin.
export class SidebarWrapper extends React.Component {

    sidebarWrapper;

    componentDidMount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps = new PerfectScrollbar(this.sidebarWrapper?.current, {
                suppressScrollX: true,
                suppressScrollY: false
            });
        }
    }

    componentWillUnmount() {
        if (navigator.platform.indexOf("Win") > -1) {
            ps.destroy();
        }
    }

    render() {
        const {className, user, headerLinks, links} = this.props;
        return (
            <div className={className} ref={ref => this.sidebarWrapper = ref}>
                {user}
                {headerLinks}
                {links}
            </div>
        );
    }
}
