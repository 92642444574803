import React from "react";
import {Dashboard } from "@material-ui/icons";
import {ViewUtils} from "../utils/ViewUtils";

export default class DashboardPage extends React.Component {

    constructor(props: any) {
        super(props);
    }

    componentDidUpdate() {

    }

    render() {
        return ViewUtils.createCardTemplate(<Dashboard/>, "Dashboard", null);
    }
}

