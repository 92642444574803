import {LocalStorage} from "./LocalStorage";

export enum ApiEndpoints {
    User = "user",
    Event = "event",
    Offer = "offer",
    Invoice = "invoice",
    Menu = "menu",
    Feedback = "feedback",
    Message = "message",
    Rating = "rating"
}

export class Networking {
    static instance = new Networking();

    // BASE_PATH = "http://localhost:8080/";
    BASE_PATH = "https://api.allicater.com/";

    sessionId = LocalStorage.instance.getSessionToken();

    public async logIn(email: string, password: string) {
        const url = this.getUrl(ApiEndpoints.User, "log-in");

        const response = await fetch(url, {
            method: "POST",
            body: new URLSearchParams({"email": email, "password": password}),
            headers: this.getDefaultHeaders()
        });
        return await response.json();
    }

    public async fetchEvents() {
        const response = await fetch(this.getUrl(ApiEndpoints.Event, "list"), { headers: this.getDefaultHeaders() });
        return await response.json();
    }

    public async fetchUserInfo() {
        const response = await fetch(this.getUrl(ApiEndpoints.User, "me"), { headers: this.getDefaultHeaders() });
        return await response.json();
    }

    private getUrl(enumeration: ApiEndpoints, endpoint: string): string {
        return this.BASE_PATH + enumeration + "/" + endpoint;
    }

    private getDefaultHeaders() {
        const result = new Headers();
        result.set("Content-Type", "application/x-www-form-urlencoded");
        result.set(LocalStorage.instance.TOKEN_KEY, this.sessionId);
        return result;
    }

    public refreshToken() {
        this.sessionId = LocalStorage.instance.getSessionToken();
    }
}
