import React from "react";

import Button from "../components/CustomButtons/Button.js";
import ReactTable from "../components/ReactTable/ReactTable.js";

import {Event} from "@material-ui/icons";
import {DateUtils} from "../utils/DateUtils";
import {ViewUtils} from "../utils/ViewUtils";

export default class RequestsPage extends React.Component {

    constructor(props) {
        super(props);
        this.classes = props;
        this.state = {
            data: []
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    render() {
        return ViewUtils.createCardTemplate(<Event/>, "Requests",
            <ReactTable
                columns={[
                    this.createColumnHeader("Id", "id"),
                    this.createColumnHeader("Location", "location"),
                    this.createColumnHeader("Date", "humanReadableDate"),
                    this.createColumnHeader("Budget", "humanReadableBudget"),
                    this.createColumnHeader("Attendees", "attendees"),
                    this.createColumnHeader("Actions", "actions")
                ]}
                data={this.parseData()}
            />);
    }

    createColumnHeader(header, accessor) {
        return { Header: header, accessor: accessor };
    }

    parseData() {
        const result = [];
        if (!this.props.data) {
            return result;
        }

        for (let i = 0; i < this.props.data.length; i++) {
            const item = this.props.data[i];
            item.humanReadableDate = DateUtils.msToLongDateString(item.date);
            item.humanReadableBudget = item.budget + "€";
            item.actions = (
                <div style={{display: "flex"}}>
                <Button size="sm" color={"rose"} onClick={() => {
                    console.log("Detail button click");
                }}>Details</Button>
                <Button size="sm" color={"success"} onClick={() => {
                    console.log("Make button click");
                }}>Make Offer</Button>
                </div>
            );
            result.push(item);
        }
        return result;
    }

}
