import React from "react";

// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
import InputAdornment from "@material-ui/core/InputAdornment";
import Icon from "@material-ui/core/Icon";

// @material-ui/icons
import {Email, Lock} from "@material-ui/icons";

// core components
import GridContainer from "../../components/Grid/GridContainer.js";
import GridItem from "../../components/Grid/GridItem.js";
import CustomInput from "../../components/CustomInput/CustomInput.js";
import Button from "../../components/CustomButtons/Button.js";
import Card from "../../components/Card/Card.js";
import CardBody from "../../components/Card/CardBody.js";
import CardHeader from "../../components/Card/CardHeader.js";
import CardFooter from "../../components/Card/CardFooter.js";

import styles from "../../assets/jss/material-dashboard-pro-react/views/loginPageStyle.js";
import {Networking} from "../../services/Networking";
import {LocalStorage} from "../../services/LocalStorage";

class LoginPage extends React.Component {

    constructor(props) {
        super(props);
        this.classes = props;
        this.state = {
            cardAnimation: "cardHidden"
        };
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({
                cardAnimation: ""
            })
        }, 700);
    }

    render() {
        const animation = this.state.cardAnimation;
        const lock = <Lock className={this.classes.classes.inputAdornmentIcon}/>;
        const email = <Email className={this.classes.classes.inputAdornmentIcon}/>;
        return (
            <div className={this.classes.classes.container}>
                <GridContainer justify="center">
                    <GridItem xs={12} sm={6} md={4}>
                        <form onSubmit={(async event => {
                            event.preventDefault();
                            const email = event.target.email.value;
                            const password = event.target.password.value;
                            const result = await Networking.instance.logIn(email, password);
                            if (!result.error) {
                                LocalStorage.instance.setSessionToken(result.sessionId);
                                Networking.instance.refreshToken();
                                this.props.parent.forceUpdate();
                            } else {
                                console.log("error:", result);
                            }
                        })}>
                            <Card login className={this.classes.classes[animation]}>
                                <CardHeader
                                    className={`${this.classes.classes.cardHeader} ${this.classes.classes.textCenter}`}
                                    color="rose"
                                >
                                    <h4 className={this.classes.classes.cardTitle}>Log in</h4>
                                    <div className={this.classes.classes.socialLine}>
                                        {[
                                            "fab fa-facebook-square",
                                            "fab fa-twitter",
                                            "fab fa-google-plus"
                                        ].map((prop, key) => { return this.createSocialButton(prop, key); })}
                                    </div>
                                </CardHeader>
                                <CardBody>
                                    {this.createCustomInput("email", "Email", "email", email)}
                                    {this.createCustomInput("password", "Password", "password", lock)}
                                </CardBody>
                                <CardFooter className={this.classes.classes.justifyContentCenter}>
                                    <Button color="rose" simple size="lg" block type={"submit"}>
                                        Let{"'"}s Go
                                    </Button>
                                </CardFooter>
                            </Card>
                        </form>
                    </GridItem>
                </GridContainer>
            </div>
        );
    }

    createSocialButton(prop, key) {
        return (
            <Button color="transparent" justIcon key={key} className={this.classes.classes.customButtonClass}>
                <i className={prop}/>
            </Button>
        );
    }

    createCustomInput(id, label, type, icon) {
        return (
            <CustomInput
                id={id}
                labelText={label}
                formControlProps={{fullWidth: true}}
                inputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <Icon className={this.classes.classes.inputAdornmentIcon}>{icon}</Icon>
                        </InputAdornment>
                    ),
                    type: type
                }}
            />
        );
    }
}

export default withStyles(styles)(LoginPage)
