import React from 'react';
import './App.css';
import Sidebar from "./components/Sidebar/Sidebar.js";

import sidebar2 from "./assets/img/allicater/word-map-portait.png";
import { Router, Route } from "react-router-dom";

import { createBrowserHistory } from "history";
import cx from "classnames";
import AdminNavbar from "./components/Navbars/AdminNavbar";
import { withStyles } from "@material-ui/core/styles";

import styles from "./assets/jss/material-dashboard-pro-react/layouts/adminStyle.js";
import "./assets/scss/material-dashboard-pro-react.scss";

import Footer from "./components/Footer/Footer";
import RequestsPage from "./views/RequestsPage";
import {Networking} from "./services/Networking";

import LoginPage from "./views/authentication/LoginPage";
import login from "./assets/img/login.jpeg";
import {LocalStorage} from "./services/LocalStorage";
import ProfilePage from "./views/ProfilePage";
import {NavigationUtils} from "./utils/NavigationUtils";
import SettingsPage from "./views/SettingsPage";
import OffersPage from "./views/OffersPage";
import InvoicesPage from "./views/InvoicesPage";
import MenuPage from "./views/MenuPage";
import DashboardPage from "./views/DashboardPage";
import FeedbackPage from "./views/FeedbackPage";
import {Dashboard, Event, Feedback, Receipt, RestaurantMenu} from "@material-ui/icons";
import {withTranslation} from "react-i18next";

const hist = createBrowserHistory();

class App extends React.Component {

    constructor(props) {
        super(props);

        this.classes = props;

        this.state = {
            mobileOpen: false,
            miniActive: false,
            image: sidebar2,
            color: "blue",
            bgColor: "black",
            fixedClasses: "dropdown",
            logo: require("./assets/img/allicater/allicater_logo_alt.svg")
        };
    }

    createRoute(title, route, icon) {
        return {name: title, path: route, icon: icon, layout: "/"}
    }
    componentDidUpdate(prevProps, prevState, snapshot) {
        this.routes = [
            this.createRoute(this.props.t("dashboard"), "dashboard", Dashboard),
            this.createRoute(this.props.t("menu"), "menu", RestaurantMenu),
            this.createRoute(this.props.t("requests"), "requests", Event),
            this.createRoute(this.props.t("invoices"), "invoices", Receipt),
            this.createRoute(this.props.t("feedback"), "feedback", Feedback)
        ];
    }

    componentDidMount = async () => {
        const response = await Networking.instance.fetchEvents();
        this.setState({
            events: response.events
        });
        const userResponse = await Networking.instance.fetchUserInfo();

        if (userResponse.error) {
            LocalStorage.instance.clear();
        }

        this.user = userResponse;
        this.forceUpdate();
    };


    handleDrawerToggle() {
        this.setState({mobileOpen: !this.state.mobileOpen});
    }

    sidebarMinimize() {
        this.setState({miniActive: !this.state.miniActive});
    }

    getActiveRoute() {
        let activeRoute = "Default Brand Text";
        for (let i = 0; i < this.routes.length; i++) {
            if (this.routes[i].collapse) {
                let collapseActiveRoute = this.getActiveRoute(this.routes[i].views);
                if (collapseActiveRoute !== activeRoute) {
                    return collapseActiveRoute;
                }
            } else {
                if (
                    window.location.href.indexOf(this.routes[i].layout + this.routes[i].path) !== -1
                ) {
                    return this.routes[i].name;
                }
            }
        }
        return activeRoute;
    }

    getRoutes() {
        return this.routes?.map((prop, key) => {
            if (prop.collapse) {
                return this.getRoutes(prop.views);
            }
            if (prop.layout === "/admin") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        component={prop.component}
                        key={key}
                    />
                );
            } else {
                return null;
            }
        });
    }

    render() {
        if (!LocalStorage.instance.isLoggedIn()) {
            // template: Auth.js
            return (
                <div>
                    <div className={this.classes.classes.wrapper}>
                        <div className={this.classes.classes.fullPage} style={{backgroundImage: "url(" + login + ")"}}>
                            <LoginPage parent={this}/>
                            <Footer white/>
                        </div>
                    </div>
                </div>
            );
        }

        this.mainPanelClasses = this.classes.classes.mainPanel + " " +
            cx({
                [this.classes.classes.mainPanelSidebarMini]: this.state.miniActive,
                [this.classes.classes.mainPanelWithPerfectScrollbar]:
                navigator.platform.indexOf("Win") > -1
            });
        return (
            <Router history={hist}>
                <div className={this.classes.classes.wrapper}>
                    <Sidebar
                        routes={this.routes}
                        logoText={"Allicater"}
                        logo={this.state.logo}
                        image={this.state.image}
                        handleDrawerToggle={this.handleDrawerToggle.bind(this)}
                        open={this.state.mobileOpen}
                        color={this.state.color}
                        bgColor={this.state.bgColor}
                        miniActive={this.state.miniActive}
                        user={this.user}
                        onClick={e => {
                            this.setState({});
                        }}
                    />
                    <div className={this.mainPanelClasses} ref={ref => {
                        this.mainPanel = ref;
                    }}>
                        <AdminNavbar
                            sidebarMinimize={this.sidebarMinimize.bind(this)}
                            miniActive={this.state.miniActive}
                            brandText={"Dashboard"}
                            handleDrawerToggle={this.handleDrawerToggle.bind(this)}
                            dashboardClick={(e) => {
                                hist.push("/dashboard");
                                this.setState({});
                            }}
                            profileClick={(e) => {
                                hist.push("/profile");
                                this.setState({});
                            }}
                            settingsClick={(e) => {
                                hist.push("/settings");
                                this.setState({});
                            }}
                            logoutClick={(e) => {
                                console.log("logoutClick");
                            }}
                        />
                        <div className={this.classes.classes.content}>
                            <div className={this.classes.classes.container}>
                                {this.decideContent()}
                            </div>
                        </div>
                        <Footer fluid/>
                    </div>

                </div>

            </Router>
        );
    }

    decideContent() {
        if (NavigationUtils.getPath() === "profile") {
            return <ProfilePage/>
        }
        if (NavigationUtils.getPath() === "requests") {
            return <RequestsPage data={this.state.events}/>
        }
        if (NavigationUtils.getPath() === "settings") {
            return <SettingsPage languageUpdate={() => {
                this.forceUpdate();
            }}/>
        }
        if (NavigationUtils.getPath() === "offers") {
            return <OffersPage/>
        }
        if (NavigationUtils.getPath() === "dashboard") {
            return <DashboardPage/>
        }
        if (NavigationUtils.getPath() === "menu") {
            return <MenuPage/>
        }
        if (NavigationUtils.getPath() === "invoices") {
            return <InvoicesPage/>
        }
        if (NavigationUtils.getPath() === "feedback") {
            return <FeedbackPage/>
        }
        return null;
    }
}

export default withTranslation()(withStyles(styles)(App));

