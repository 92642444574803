import React from "react";
import {Menu}  from "@material-ui/icons";
import {ViewUtils} from "../utils/ViewUtils";

export default class MenuPage extends React.Component {

    constructor(props: any) {
        super(props);
    }

    componentDidUpdate() {

    }

    render() {
        return ViewUtils.createCardTemplate(<Menu/>, "Menu", null);
    }
}

