
export class DateUtils {

    static msToLongDateString(ms: any) {
        if (ms === undefined || ms === null || ms === "") {
            return "-";
        }

        if (typeof ms === "string") {
            ms = parseInt(ms);
        }

        const language = "et";
        const locale = language + "-" + language.toUpperCase();
        return DateUtils.MSToDate(ms).toLocaleDateString(locale);
    }

    static MSToDate(ms: number) {
        return new Date(ms);
    }

}
