import React from "react";
import {FiberManualRecord, Settings} from "@material-ui/icons";
import {ViewUtils} from "../utils/ViewUtils";
import {LocalStorage} from "../services/LocalStorage";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";

import styles from "../assets/jss/material-dashboard-pro-react/views/regularFormsStyle";
import { withStyles } from "@material-ui/core/styles";
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

class SettingsPage extends React.Component {

    constructor(props) {
        super(props);
        this.classes = props.classes;
        this.state = { selectedValue: LocalStorage.instance.settings.language };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {

    }

    async handleChange(e) {
        const language = e.target.value;
        LocalStorage.instance.updateLanguage(language);
        await i18n.changeLanguage(language);
        this.setState({ selectedValue: language });
        this.props.languageUpdate();
    }

    render() {
        return ViewUtils.createCardTemplate(<Settings/>, this.props.t("settings"), <div>
            <h5>{this.props.t("language")}</h5>
            {this.createContent("Eesti keel", "et")}
            {this.createContent("English", "en")}
        </div>);
    }

    createContent(label, value) {
        return (
            <div className={this.classes.checkboxAndRadio + " " + this.classes.checkboxAndRadioHorizontal}>
                {this.createFormControl(label, value)}
            </div>
        );
    }

    createFormControl(label, value) {
        return (
            <FormControlLabel
                control={
                    <Radio
                        checked={this.state.selectedValue === value}
                        onChange={this.handleChange.bind(this)}
                        value={value}
                        name="radio button demo"
                        aria-label="A"
                        icon={<FiberManualRecord className={this.classes.radioUnchecked}/>}
                        checkedIcon={<FiberManualRecord className={this.classes.radioChecked}/>}
                        classes={{checked: this.classes.radio, root: this.classes.radioRoot}}
                    />
                }
                classes={{label: this.classes.label, root: this.classes.labelRoot}}
                label={label}
            />
        );
    }
}

export default withTranslation()(withStyles(styles)(SettingsPage));
